import { useState, useEffect } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MSApi } from "../components/MSApi";
import MSLogin from "../components/MSLogin";
import GetLicensing from "../components/GetLicensing";
import Report from "./Report";
import { configuration } from "../config/MSConfig";
import Spinner from "../components/Spinner";
import SelectedCustomer from "./SelectedCustomer";

export const pca = new PublicClientApplication(configuration);

const PartnerCenterAssessment = () => {
  const { getCustomers, getCustomerSubscribedSkus, state } = MSApi();

  let [customers, setCustomers] = useState(state.customers);
  let [loading, setLoading] = useState(false);
  let [licenseLoading, setLicenseLoading] = useState(false);
  let [selectedCustomer, setSelectedCustomer] = useState(false);

  const updateCache = (data) => {
    let currentData = JSON.parse(localStorage.getItem("onboardingState"));
    currentData.customers = data;
    localStorage.setItem("onboardingState", JSON.stringify(currentData));
    return currentData;
  };

  const updateCacheSingle = (customer) => {
    let currentData = JSON.parse(localStorage.getItem("onboardingState"));
    let customersTemp = currentData.customers.map((ele) => {
      if (ele.id === customer.id) {
        ele = customer;
      }
      return ele;
    });
    setCustomers(customersTemp);
    updateCache(customersTemp);
  };

  useEffect(() => {
    console.log("Change to customers detected");
  }, [customers]);

  useEffect(() => {
    if (state?.customers) {
      setCustomers(state.customers);
    }
  }, [state]);

  const getTenantLicenses = async () => {
    setLicenseLoading(true);
    customers?.map(async (ele) => {
      if (ele.readable) {
        ele.state = 1;
        let output = await getCustomerSubscribedSkus(state.accessToken, ele);
        const licenses = (await output.json()).items;
        if (licenses) {
          ele.state = 2;
          ele.licenses = licenses;
        } else {
          ele.state = 3;
        }
        updateCacheSingle(ele);
      }
      return ele;
    });
    setCustomers();
    updateCache(customers);
    setLicenseLoading(false);
  };

  const getCustomersRefresh = async () => {
    setLoading(true);
    await getCustomers();
    setLoading(false);
  };

  const handleSelectedCustomer = (customerId) => {
    setSelectedCustomer(customerId);
    window.scrollTo(0, 0);
  };

  const clearSelectedCustomer = () => {
    setSelectedCustomer(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className="App">
      <div className="container mx-auto">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          {loading && (
            <Spinner styles="mt-4" loadingText="Loading GDAP Assessment..." />
          )}

          {!loading && state?.customers && selectedCustomer && (
            <SelectedCustomer
              selectedCustomer={selectedCustomer}
              clearCustomer={clearSelectedCustomer}
            />
          )}

          {!loading && state?.customers && !selectedCustomer && (
            <>
              {/* <GetLicensing customers={setLoadingState} /> */}
              <Report
                customers={customers}
                onCustomerSelected={handleSelectedCustomer}
                getTenantLicenses={getTenantLicenses}
                loading={loading}
                licenseLoading={licenseLoading}
              />
            </>
          )}

          {!loading && !state?.customers && (
            <MSLogin onClick={getCustomersRefresh} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerCenterAssessment;
