import { library } from "@fortawesome/fontawesome-svg-core";
import { faMicrosoft, faFontAwesome } from "@fortawesome/free-brands-svg-icons";
import { faIdCard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faMicrosoft, faIdCard);

const GetLicensing = ({ customers }) => {
  return (
    <div className="flex flex-col items-center py-10">
      <button
        type="button"
        className="mx-auto inline-flex items-center gap-2 rounded border border-inforcer-cyan bg-inforcer-cyan px-20 py-3 text-white hover:bg-transparent hover:text-inforcer-cyan focus:outline-none focus:ring active:text-inforcer-cyan"
        onClick={customers}
      >
        <span className="text-lg font-medium">Start Licensing Report</span>

        <span className="text-xl font-large">
          <FontAwesomeIcon icon="fa-regular fa-id-card" />
        </span>
      </button>
    </div>
  );
};

export default GetLicensing;
