import { useState, useEffect, useRef } from "react";
import { MSApi } from "../components/MSApi";
import {
  minimumRoles,
  minimumLicensingRoles,
  globalAdminRole,
} from "../config/MSConfig";
import Modal from "../components/Modal";
import GetLicensing from "../components/GetLicensing";
import ListTable from "../components/ListTable/ListTable";
import { MRT_GlobalFilterTextField } from "material-react-table";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import TenantPieChart from "../components/charts/TenantPieChart";
import LicenseBarChart from "../components/charts/LicenseBarChart";
import TenantAreaChart from "../components/charts/TenantLineChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import PDFExportButton from "../components/PDFExportButton";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ViewCustomer from "../components/ViewCustomer";
import Spinner from "../components/Spinner";

const Report = ({
  customers,
  onCustomerSelected,
  getTenantLicenses,
  loading,
  licenseLoading,
}) => {
  let [isOpen, setIsOpen] = useState(false);
  let [modalData, setModalData] = useState(0);

  const chartRef = useRef(null);

  const handleSelectedCustomer = (customer) => {
    onCustomerSelected(customer);
  };

  const [customerCount, setCustomerCount] = useState(0);
  const [gdapEnabledCustomers, setGdapEnabledCustomers] = useState(0);
  const [gdapReadyCustomers, setGdapReadyCustomers] = useState(0);
  const [tenantsWithBusinessE3, setTenantsWithBusinessE3] = useState(0);
  const [tenantsWithBusinessE5, setTenantsWithBusinessE5] = useState(0);
  const [tenantsWithBusinessStandard, setTenantsWithBusinessStandard] =
    useState(0);
  const [tenantsWithBusinessPremium, setTenantsWithBusinessPremium] =
    useState(0);
  const [customerTable, setCustomerTable] = useState("");
  useEffect(() => {
    const processCustomers = async () => {
      if (customers) {
        // Local variables to accumulate counts
        let gdapEnabledCount = 0;
        let gdapReadyCount = 0;
        let businessPremiumCount = 0;

        setCustomerCount(customers?.length);

        const updatedCustomerTable = customers.map((ele, index) => {
          let missingRoles = [];
          let missingRolesHTML = <p>Loading...</p>;

          // Increment GDAP Enabled count
          if (ele?.unifiedRoles?.length > 0) gdapEnabledCount += 1;

          // Check for Global Admin role
          let hasGlobalAdmin = ele?.unifiedRoles?.some(
            (customerRole) =>
              globalAdminRole.id === customerRole.roleDefinitionId
          );

          // Check for Business Premium license
          let hasBusinessPremium = ele?.licenses?.some(
            (license) =>
              license.productSku.name === "Microsoft 365 Business Premium" ||
              license.productSku?.sku === "SBP"
          );
          if (hasBusinessPremium) businessPremiumCount += 1;

          if (!hasGlobalAdmin) {
            minimumRoles.forEach((role) => {
              let validRole = ele?.unifiedRoles?.some(
                (customerRole) => role.id === customerRole.roleDefinitionId
              );

              if (!validRole) missingRoles.push(role.displayName);
            });

            if (missingRoles?.length === 0) {
              gdapReadyCount += 1;
              ele.readable = true;
              missingRolesHTML = <p>There are no missing roles!</p>;
            } else {
              missingRolesHTML = (
                <div className="mx-auto max-w-3xl text-center">
                  <h1 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary">
                    Missing GDAP Roles
                  </h1>
                  <ul>
                    {missingRoles.map((role) => (
                      <li key={ele?.id}>{role}</li>
                    ))}
                  </ul>
                </div>
              );
            }
          } else {
            gdapReadyCount += 1;
            ele.readable = true;
            missingRolesHTML = (
              <p>
                This customer has the <b>Global Administrator</b> role assigned.
              </p>
            );
          }

          let missingRoleBadge;
          if (ele?.unifiedRoles?.length > 0) {
            if (missingRoles?.length === 0) {
              missingRoleBadge = (
                <span className="inline-flex items-center justify-center rounded-full bg-emerald-100 px-2.5 py-0.5 text-emerald-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="-ms-1 me-1.5 size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <p className="whitespace-nowrap text-sm">inforcer Ready</p>
                </span>
              );
            } else {
              missingRoleBadge = (
                <span className="inline-flex items-center justify-center rounded-full bg-amber-100 px-2.5 py-0.5 text-amber-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="-ms-1 me-1.5 size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>
                  <p className="whitespace-nowrap text-sm">
                    Missing {missingRoles?.length} Roles
                  </p>
                </span>
              );
            }
          } else {
            missingRoleBadge = (
              <span className="inline-flex items-center justify-center rounded-full bg-red-200 px-2.5 py-0.5 text-red-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="-ms-1 me-1.5 size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                  />
                </svg>
                <p className="whitespace-nowrap text-sm">No Relationship</p>
              </span>
            );
          }
          ele.missingRoles = missingRoles;
          return ele; // Return the modified element for updatedCustomerTable
        });

        // Set final counts in state once processing is complete
        setGdapEnabledCustomers(gdapEnabledCount);
        setGdapReadyCustomers(gdapReadyCount);
        setTenantsWithBusinessPremium(businessPremiumCount);
        setCustomerTable(updatedCustomerTable);
      }
    };

    processCustomers();
  }, [customers]);

  const calculateDaysRemaining = (endDateTime) => {
    if (!endDateTime) return "N/A";
    const endDate = new Date(endDateTime);
    const currentDate = new Date();
    const timeDifference = endDate - currentDate;
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysRemaining >= 0 ? daysRemaining : "Expired";
  };

  const licensesRequested = customers?.some(
    (customer) => customer.licenses && customer.licenses.length > 0
  );

  const formatAutoRenewalStatus = (status) => {
    switch (status) {
      case "PT0S":
        return "Not enabled";
      case "P180D":
        return "Enabled";
      default:
        return "Not found";
    }
  };
  const calculateUnconsumedLicenses = (licenses) => {
    if (!licenses || licenses.length === 0) {
      return 0; // No licenses available
    }

    let totalConsumedUnits = 0
    let totalActiveUnits   = 0
    licenses.map((license) => {
      let licenseName = license.productSku.name
      if(licenseName.includes("Microsoft 365 Business") || licenseName.includes("Microsoft 365 E") || licenseName.includes("Microsoft 365 F")) {
        // This is a primary license
        totalConsumedUnits += license.consumedUnits
        totalActiveUnits += license.activeUnits
      }
    })

    let difference = totalActiveUnits - totalConsumedUnits
    if(difference < 0){
      difference = 0
    }

    return totalActiveUnits - totalConsumedUnits;
  };

  const tableColumns = [
    {
      header: "View",
      accessor: "companyProfile.tenantId",
      Cell: ({ row }) => {
        return (
          <button
            onClick={() => handleSelectedCustomer(row.original)}
            className="rounded border text-sm border-inforcer-navy bg-inforcer-navy px-2 py-2 text-white hover:bg-transparent hover:text-inforcer-navy focus:outline-none focus:ring active:text-inforcer-navy"
          >
            <FontAwesomeIcon className="mr-1" icon={faEye} />
            View
          </button>
        );
      },
    },
    {
      header: "Tenant Name",
      accessor: "companyProfile.companyName",
      Cell: ({ row }) => (
        <span className="whitespace-nowrap">
          {row.original.companyProfile.companyName}
        </span>
      ),
    },
    {
      header: "GDAP Status",
      accessor: "gdapStatus",
      Cell: ({ row }) => {
        const { unifiedRoles, readable, missingRoles } = row.original;
        let gdapStatus = "No Relationship";
        if (unifiedRoles?.length > 0) {
          if (missingRoles?.length === 0) {
            gdapStatus = "inforcer Ready";
          } else {
            gdapStatus = `Missing ${missingRoles?.length} Roles`;
          }
        }
        return gdapStatus === "inforcer Ready" ? (
          <span className="flex w-fit items-center justify-center rounded-full bg-emerald-100 px-2.5 py-0.5 text-emerald-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="-ms-1 me-1.5 size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p className="whitespace-nowrap text-sm">inforcer Ready</p>
          </span>
        ) : gdapStatus === "No Relationship" ? (
          <span className="flex w-fit items-center justify-center rounded-full bg-red-200 px-2.5 py-0.5 text-red-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="-ms-1 me-1.5 size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              />
            </svg>
            <p className="whitespace-nowrap text-sm">No Relationship</p>
          </span>
        ) : (
          <span className="flex w-fit items-center justify-center rounded-full bg-amber-100 px-2.5 py-0.5 text-amber-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="-ms-1 me-1.5 size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              />
            </svg>
            <p className="whitespace-nowrap text-sm">
              Missing {missingRoles?.length} Roles
            </p>
          </span>
        );
      },
      filterFn: (row, id, filterValue) => {
        const { unifiedRoles, readable, missingRoles } = row.original;
        let gdapStatus = "No Relationship";
        if (unifiedRoles?.length > 0) {
          gdapStatus = readable
            ? "inforcer Ready"
            : `Missing ${missingRoles?.length} Roles`;
        }
        return gdapStatus.toLowerCase().includes(filterValue.toLowerCase());
      },
      enableColumnFilter: true,
    },
    {
      header: "Days Remaining",
      accessor: "endDateTime",
      Cell: ({ row }) => {
        const daysRemaining = calculateDaysRemaining(row.original.endDateTime);
        let badgeStyle = "bg-emerald-100 text-emerald-700"; // Default green badge

        // Define badge colors based on days remaining
        if (daysRemaining === "N/A") {
          badgeStyle = "bg-none text-inherit";
        } else if (daysRemaining < 30) {
          badgeStyle = "bg-red-200 text-red-700";
        } else if (daysRemaining < 100) {
          badgeStyle = "bg-amber-100 text-amber-700";
        }

        return (
          <span
            className={`flex w-fit items-center justify-center gap-1 rounded-full px-2.5 py-0.5 text-sm ${badgeStyle}`}
          >
            {daysRemaining !== "N/A" && (
              <FontAwesomeIcon icon={faClock} className="mr-1" />
            )}
            <p className="whitespace-nowrap text-sm">
              {daysRemaining === "N/A"
                ? daysRemaining
                : `${daysRemaining} days`}
            </p>
          </span>
        );
      },
    },

    {
      header: "Auto Renewal Status",
      accessor: "autoExtendDuration",
      Cell: ({ row }) => (
        <span>{formatAutoRenewalStatus(row.original.autoExtendDuration)}</span>
      ),
    },
    ...(licensesRequested
      ? [
          {
            header: "Unconsumed Licenses",
            accessor: "licenses",
            Cell: ({ row }) => (
              <span>{calculateUnconsumedLicenses(row.original.licenses)}</span>
            ),
          },
        ]
      : []),

    {
      header: "Tenant Domain",
      accessor: "companyProfile.domain",
    },
  ];

  // Helper function to flatten a single object
  const flattenObject = (obj, parent = "", res = {}) => {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const propName = parent ? `${parent}.${key}` : key;

        // Handle arrays of objects like unifiedRoles
        if (Array.isArray(obj[key])) {
          if (obj[key].length > 0 && typeof obj[key][0] === "object") {
            // If the array contains objects, extract the roleDefinitionId from each object
            res[propName] = obj[key]
              .map((item) => item.roleDefinitionId || JSON.stringify(item))
              .join(", ");
          } else {
            // If the array contains non-object items, join them as a comma-separated string
            res[propName] = obj[key].join(", ");
          }
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          flattenObject(obj[key], propName, res); // Recursively flatten nested objects
        } else {
          res[propName] = obj[key]; // Assign other values as-is
        }
      }
    }
    return res;
  };

  let tenantReportData =
    customers?.map((data) => {
      let GdapState = (data) => {
        let state = "No Relationship";
        if (data?.unifiedRoles?.length > 0) {
          if (data.missingRoles?.length === 0) {
            state = "inforcer Ready";
          } else {
            state = `Missing ${data.missingRoles?.length} Roles`;
          }
        }
        return state;
      };

      return {
        tenantId: data?.companyProfile.tenantId ?? "",
        domain: data?.companyProfile.domain ?? "",
        companyName: data?.companyProfile.companyName ?? "",
        gdapDaysRemaining: calculateDaysRemaining(data.endDateTime) ?? "",
        gdapStatus: GdapState(data) ?? "",
        missingRoles: data.missingRoles ?? "",
        autoRenewalStatus:
          formatAutoRenewalStatus(data.autoExtendDuration) ?? "",
        unconsumedLicenses: calculateUnconsumedLicenses(data.licenses) ?? "",
      };
    }) ?? [];

  const clearLocalOnboardingState = () => {
    localStorage.removeItem("onboardingState");
    window.location.reload();
  };

  const allTenantsLicensesEmpty =
    tenantsWithBusinessStandard === 0 &&
    tenantsWithBusinessPremium === 0 &&
    tenantsWithBusinessE3 === 0 &&
    tenantsWithBusinessE5 === 0;

  return (
    <div className="Report">
      <Modal
        closeOnBlur={true}
        children={modalData}
        isOpen={isOpen}
        setModalOpen={() => setIsOpen(false)}
        dialogPanelWidth=""
      />
      <div ref={chartRef}>
        <div className="flex justify-between mb-8 max-md:flex-col">
          <div className="flex-1 p-5 border border-gray-300 rounded-lg shadow-sm bg-white max-md:mb-4">
            <TenantPieChart
              customerCount={customerCount}
              gdapEnabledCustomers={gdapEnabledCustomers}
              gdapReadyCustomers={gdapReadyCustomers}
            />
          </div>
          <div className="flex-1 px-5 py-10 ml-8 border border-gray-300 rounded-lg shadow-sm bg-white max-md:ml-0">
            {allTenantsLicensesEmpty ? (
              <div className="flex justify-center items-center flex-col">
                <p className="text-gray-500 mt-16 mb-6">
                  Request data to see breakdown of tenants licenses.
                </p>
                <button
                  type="button"
                  className="mx-auto inline-flex items-center gap-2 rounded border border-inforcer-navy bg-inforcer-navy px-20 py-3 text-white hover:bg-transparent hover:text-inforcer-navy focus:outline-none focus:ring active:text-inforcer-navy cursor-pointer"
                  onClick={getTenantLicenses}
                >
                  <span className="text-lg font-medium">
                    Start Licensing Report
                  </span>

                  <span className="text-xl font-large">
                    <FontAwesomeIcon icon="fa-regular fa-id-card" />
                  </span>
                </button>
              </div>
            ) : loading || licenseLoading ? (
              <Spinner styles="mt-4" loadingText="Fetching tenant licenses" />
            ) : (
              <LicenseBarChart
                customerCount={customerCount}
                tenantsWithBusinessStandard={tenantsWithBusinessStandard}
                tenantsWithBusinessPremium={tenantsWithBusinessPremium}
                tenantsWithBusinessE3={tenantsWithBusinessE3}
                tenantsWithBusinessE5={tenantsWithBusinessE5}
              />
            )}
          </div>
        </div>
        <div className="flex-1 px-5 py-8 pb-6 mb-6 border border-gray-300 rounded-lg shadow-sm bg-white max-md:ml-0">
          <TenantAreaChart tenants={tenantReportData} />
        </div>
      </div>

      <ListTable
        uniqueKey="id"
        columns={tableColumns}
        sorting={[
          {
            id: "gdapStatus",
            desc: false,
          },
        ]}
        data={customers ?? []}
        enableGlobalFilter
        loading={loading || licenseLoading}
        renderTopToolbar={({ table }) => (
          <div className="flex justify-between items-center py-2">
            <MRT_GlobalFilterTextField table={table} />

            <div className="flex gap-3">
              <PDFExportButton tenants={tenantReportData} chartRef={chartRef} />
              <CSVLink
                className={`rounded border text-sm border-inforcer-cyan bg-inforcer-cyan px-2 py-2 text-white hover:bg-transparent hover:text-inforcer-cyan focus:outline-none focus:ring active:text-inforcer-cyan cursor-pointer ${
                  customers?.length === 0
                    ? "pointer-events-none bg-gray-500 opacity-50"
                    : "bg-inforcer-cyan"
                }`}
                data={tenantReportData}
                filename="inforcer_gdap_assessment.csv"
              >
                <FontAwesomeIcon className="mr-2" icon={faDownload} />
                Export to CSV
              </CSVLink>
            </div>
          </div>
        )}
      />
      <button
        onClick={clearLocalOnboardingState}
        className="text-sm underline cursor-pointer"
      >
        Clear data
      </button>
    </div>
  );
};

export default Report;
