import React, { useMemo } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const TenantAreaChart = ({ tenants }) => {
  // Prepare data for the area chart
  const data = useMemo(() => {
    const daysRemainingMap = tenants.reduce((acc, tenant) => {
      const days = tenant.gdapDaysRemaining;
      acc[days] = (acc[days] || 0) + 1;
      return acc;
    }, {});

    // Convert map to array of { days: count } objects for the area chart
    return Object.entries(daysRemainingMap).map(([days, count]) => ({
      days: parseInt(days, 10),
      count,
    }));
  }, [tenants]);

  return (
    <div className="flex items-center justify-center">
      <ResponsiveContainer width="100%" height={160}>
        <AreaChart data={data}>
          {/* <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#178BDB" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#178BDB" stopOpacity={0.2} />
            </linearGradient>
          </defs> */}
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="days"
            label={{
              value: "Days Remaining",
              position: "insideBottomLeft",
              dy: 22,
            }}
          />
          <YAxis
            label={{
              value: "Tenant Count",
              angle: -90,
              dx: 5,
              dy: 60,
              position: "insideLeft",
            }}
          />
          <Tooltip
            formatter={(value, name) => [`${value}`, "Tenant Count"]}
            labelFormatter={(label) => `${label} Days remaining`}
          />
          <Legend />
          <Area
            type="monotone"
            dataKey="count"
            name="Relationship Timeline"
            stroke="#178BDB"
            fill="#178BDB"
            fillOpacity={0.3}
            // fill="url(#colorGradient)"
            // label={({ x, y, value }) => (
            //   <text
            //     x={x}
            //     y={y - 10} // Adjust positioning of labels on the area
            //     fill="#333"
            //     fontSize="10px"
            //     textAnchor="middle"
            //   >
            //     {value}
            //   </text>
            // )}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TenantAreaChart;
