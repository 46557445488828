import React, { useMemo } from "react";
import { PieChart, Pie, Cell, Tooltip, Label, Legend } from "recharts";

const TenantPieChart = ({
  customerCount,
  gdapEnabledCustomers,
  gdapReadyCustomers,
}) => {
  const COLORS = ["#178BDB", "#171B3A", "#a1a1aa"];

  const data = useMemo(() => {
    const nonGdapCustomers = customerCount - gdapEnabledCustomers;
    const gdapReadyButNotEnabled = gdapEnabledCustomers - gdapReadyCustomers;

    return [
      { name: "GDAP Enabled Customers", value: gdapEnabledCustomers },
      {
        name: "GDAP Ready but not GDAP Enabled",
        value: gdapReadyButNotEnabled,
      },
      { name: "Non-GDAP Enabled Customers", value: nonGdapCustomers },
    ];
  }, [customerCount, gdapEnabledCustomers, gdapReadyCustomers]);

  const renderCustomizedLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox;
    return (
      <g>
        <text
          x={cx}
          y={cy + 8}
          textAnchor="middle"
          fontSize="54px"
          fontWeight="bold"
          fill="#171B3A"
        >
          {customerCount}
        </text>
        <text
          x={cx}
          y={cy + 30}
          textAnchor="middle"
          fontSize="16px"
          fill="#6b7280"
        >
          Tenants
        </text>
      </g>
    );
  };

  const renderLegendText = (value, entry) => {
    const { value: count } = entry.payload;
    return `${value}: ${count}`;
  };

  return (
    <div className="flex items-center justify-center">
      <PieChart width={320} height={310}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={90}
          outerRadius={112}
          paddingAngle={2}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <Label content={renderCustomizedLabel} position="center" />
        </Pie>
        <Tooltip />
        <Legend
          formatter={renderLegendText}
          align="left"
          wrapperStyle={{ bottom: "-5px" }}
        />
      </PieChart>
    </div>
  );
};

export default TenantPieChart;
