/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import { MRT_GlobalFilterTextField } from "material-react-table";

export const defaultCellSx = {
  borderLeft: "none",
  borderRight: "none",
};

export const defaultTableProperties = {
  renderTopToolbar: ({ table }) => (
    <div className="w-full flex flex-row gap-2 py-2">
      <MRT_GlobalFilterTextField table={table} />
    </div>
  ),
  columnFilterDisplayMode: "popover",
  enableStickyHeader: true,
  enableColumnOrdering: false,
  enableColumnPinning: false,
  enableColumnActions: false,
  enableColumnFilters: true,
  enableGlobalFilter: true,
  enableFullScreenToggle: false,
  enableHiding: false,
  enableDensityToggle: false,
  enableFacetedValues: true,
  positionToolbarAlertBanner: "none",
  initialState: {
    showGlobalFilter: true,
  },
  muiSelectCheckboxProps: {
    color: "primary",
  },
  muiTableContainerProps: {
    sx: { maxHeight: "100%", overflow: "auto" },
  },
  muiToolbarAlertBannerProps: {
    sx: {
      marginBottom: "16px",
    },
  },
  muiTableHeadCellProps: {
    sx: {
      overflow: "visible",
      ...defaultCellSx,
    },
  },
  muiTableHeadRowProps: {
    sx: {
      boxShadow: "none",
    },
  },
  muiTableBodyCellProps: {
    sx: {
      fontFamily: "inherit",
      color: "rgba(23, 27, 58, 0.85)",
      overflow: "visible",
      ...defaultCellSx,
    },
  },
  muiTableFooterCellProps: {
    sx: {
      borderLeft: "none",
      borderRight: "none",
    },
  },
  muiTablePaperProps: {
    sx: {
      boxShadow: "none",
      overflow: "visible",
    },
  },
  muiTableFooterProps: {
    sx: {
      boxShadow: "none",
    },
  },
  displayColumnDefOptions: {
    "mrt-row-actions": {
      header: "Settings",
      size: 100,
    },
  },
  positionGlobalFilter: "left",
};

export default { tableInstance: defaultTableProperties };
