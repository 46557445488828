import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";

const ReturnButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="rounded border text-sm border-inforcer-navy bg-inforcer-navy px-2 py-2 text-white hover:bg-transparent hover:text-inforcer-navy focus:outline-none focus:ring active:text-inforcer-navy cursor-pointer"
  >
    <FontAwesomeIcon className="mr-1" icon={faUndo} />
    Return
  </button>
);

export default ReturnButton;
