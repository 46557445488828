import { MRT_GlobalFilterTextField } from "material-react-table";
import GdapPieChart from "../components/charts/GdapPieChart";
import ListTable from "../components/ListTable/ListTable";
import ReturnButton from "../components/ReturnButton";
import { allEntraIDRoles, minimumRoles } from "../config/MSConfig";

const SelectedCustomer = ({ selectedCustomer, clearCustomer }) => {
  let totalAppliedGdapRoles = 0;
  let totalGdapRoles = allEntraIDRoles?.length;
  let missingInforcerGdapRoles = 0;
  let appliedInforcerGdapRoles = 0;

  allEntraIDRoles.map((role) => {
    role.Status = "Not Established";
    role.Required = false;

    minimumRoles.map((minimumRole) => {
      if (role["Template ID"] === minimumRole.id) {
        role.Required = true;
      }
    });
    selectedCustomer?.unifiedRoles.map((customerRole) => {
      if (role["Template ID"] === customerRole.roleDefinitionId) {
        role.Status = "Ready";
        totalAppliedGdapRoles++;
      }
    });
    if (role.Status === "Ready" && role.Required) {
      appliedInforcerGdapRoles++;
    }
    if (role.Status === "Not Established" && role.Required) {
      missingInforcerGdapRoles++;
    }
  });

  const tableColumns = [
    {
      header: "Entra ID Role Name",
      accessor: "Role",
    },
    {
      header: "Role Description",
      accessor: "Description",
    },
    {
      header: "Status",
      accessor: "Status",
      Cell: ({ row }) => {
        let badgeStyle = "bg-emerald-100 text-emerald-700"; // Default green badge

        if (row.original.Status === "Not Established") {
          if (row.original.Required) {
            badgeStyle = "bg-red-200 text-red-700";
          } else {
            badgeStyle = "bg-amber-100 text-amber-700";
          }
        }

        return (
          <span
            className={`flex w-fit items-center justify-center gap-1 rounded-full px-2.5 py-0.5 text-sm ${badgeStyle}`}
          >
            <p className="whitespace-nowrap text-sm">{row.original.Status}</p>
          </span>
        );
      },
    },
    {
      header: "Required",
      accessor: "Required",
      Cell: ({ row }) => {
        let badgeStyle = "bg-amber-100 text-amber-700";
        let message = "Not Required";
        if (row.original.Required) {
          badgeStyle = "bg-emerald-100 text-emerald-700"; // Default green badge
          message = "inforcer";
        }

        return (
          <span
            className={`flex w-fit items-center justify-center gap-1 rounded-full px-2.5 py-0.5 text-sm ${badgeStyle}`}
          >
            <p className="whitespace-nowrap text-sm">{message}</p>
          </span>
        );
      },
    },
  ];

  let inforcerReady = false;
  let message = "Not Ready";
  if (missingInforcerGdapRoles === 0) {
    inforcerReady = true;
    message = "Ready";
  }

  const customerDetailColumns = [
    {
      header: "Property",
      accessor: "Property",
    },
    {
      header: "Value",
      accessor: "Value",
      Cell: ({ row }) => {
        if (row.original.Property === "inforcer Onboarding Status") {
          let badgeStyle = "bg-red-200 text-red-700";
          if (row.original.Value === "Ready") {
            badgeStyle = "bg-emerald-100 text-emerald-700"; // Default green badge
          }
          return (
            <span
              className={`flex w-fit items-center justify-center gap-1 rounded-full px-2.5 py-0.5 text-sm ${badgeStyle}`}
            >
              <p className="whitespace-nowrap text-sm">{message}</p>
            </span>
          );
        } else {
          return row.original.Value;
        }
      },
    },
  ];

  const customerDetail = [
    {
      Property: "Customer Name",
      Value: selectedCustomer.companyProfile.companyName,
    },
    {
      Property: "Tenant Id",
      Value: selectedCustomer.companyProfile.tenantId,
    },
    {
      Property: "inforcer Onboarding Status",
      Value: message,
    },
  ];

  return (
    <>
      <div className="flex justify-between mb-8 max-md:flex-col">
        <div className="flex-1 p-5 border border-gray-300 rounded-lg shadow-sm bg-white max-md:mb-4">
          <ListTable
            uniqueKey="id"
            columns={customerDetailColumns}
            data={customerDetail ?? []}
            sorting={[
              {
                id: "Property",
                desc: false,
              },
            ]}
            enablePagination={false}
            renderTopToolbar={false}
            enableGlobalFilter={false}
          />
        </div>
        <div className="flex-1 px-5 py-10 ml-8 border border-gray-300 rounded-lg shadow-sm bg-white max-md:ml-0">
          <GdapPieChart
            totalGdapRoles={totalGdapRoles}
            totalAppliedGdapRoles={totalAppliedGdapRoles}
            missingInforcerGdapRoles={missingInforcerGdapRoles}
            appliedInforcerGdapRoles={appliedInforcerGdapRoles}
          />
        </div>
      </div>
      <ListTable
        uniqueKey="id"
        columns={tableColumns}
        data={allEntraIDRoles ?? []}
        sorting={[
          {
            id: "Required",
            desc: true,
          },
        ]}
        renderTopToolbar={({ table }) => (
          <div className="flex justify-between items-center py-2">
            <MRT_GlobalFilterTextField table={table} />

            <div className="flex gap-3">
              <ReturnButton onClick={clearCustomer} />
            </div>
          </div>
        )}
      />
      <button
        onClick={clearCustomer}
        className="text-sm underline cursor-pointer"
      >
        Go Back
      </button>
    </>
  );
};

export default SelectedCustomer;
