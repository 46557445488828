import { useState, useEffect } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  commonLoginAuthority,
  configuration,
  cspAuthScopes,
  delegatedAdminAuthScopes,
} from "../config/OnboardingConfig";

const loadState = (isRefresh = false) => {
  const localState = localStorage.getItem("onboardingState");

  if (!localState || isRefresh)
    return {
      cspEpaAdded: false,
      cspEpaRemoved: false,
      onboardingCode: null,
      accessToken: null,
      customers: null,
      customerProgress: [],
    };

  return JSON.parse(localState);
};

const saveState = (state, isRefresh = false) => {
  if (isRefresh) return state;
  localStorage.setItem("onboardingState", JSON.stringify(state));
  return state;
};

export const clearLocalOnboardingState = () => {
  localStorage.removeItem("onboardingState");
};

const encodeFormBody = (jsonBody) => {
  const formBody = [];

  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const property in jsonBody) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(jsonBody[property]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  }

  return formBody.join("&");
};

const cspPca = new PublicClientApplication(configuration);

export const MSApi = (isRefresh = false) => {
  const [state, setState] = useState(loadState(isRefresh));

  const [error, setError] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initialisePca = async () => await cspPca.initialize();
    initialisePca();
  }, []);

  const refreshCspPcaCredential = async (
    authority,
    scopes,
    acquireTokenSilent,
    prompt = "select_account"
  ) => {
    let response;

    if (acquireTokenSilent) {
      try {
        response = await cspPca.acquireTokenSilent({
          scopes,
          authority,
        });
      } catch (acquireTokenSilentError) {
        try {
          response = await cspPca.acquireTokenPopup({
            scopes,
            authority,
          });
        } catch (acquireTokenInteractiveError) {
          console.error(acquireTokenInteractiveError);
          setError("Failed to authenticate interactively");
          setLoading(false);
        }
      }
    } else {
      try {
        response = await cspPca.acquireTokenPopup({
          scopes,
          authority,
          prompt,
        });
      } catch (interactiveLoginError) {
        console.error(interactiveLoginError);
        setError("Failed to authenticate interactively");
        setLoading(false);
      }
    }

    if (response !== undefined && authority === commonLoginAuthority) {
      cspPca.setActiveAccount(response.account);
    }

    return response;
  };

  const getCustomerSubscribedSkus = async (accessToken, customer) => {
    let customerId = customer?.id;
    try {
      return await fetch(
        `https://api.partnercenter.microsoft.com/v1/customers/${customerId}/subscribedskus`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (createSubscribedSkusError) {
      console.error(createSubscribedSkusError);
      setError("Failed to get subscribedskus from customer" + { customerId });
    }
  };

  const fetchPartnerCenterToken = async (accessToken) =>
    await fetch("https://api.partnercenter.microsoft.com/generatetoken", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: encodeFormBody({ grant_type: "jwt_token" }),
    });

  const fetchPartnerCenterCustomers = async (token) =>
    await fetch(
      "https://api.partnercenter.microsoft.com/v1/customers?size=1000",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

  const fetchPartnerCenterDomains = async (token) => {
    let allResults;
    let nextUrlToFetchFrom = "https://graph.microsoft.com/beta/domains";

    // TODO - Refactor with recursion
    try {
      const resp = await fetch(nextUrlToFetchFrom, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const respJson = await resp.json();
      nextUrlToFetchFrom = respJson["@odata.nextLink"];
      allResults = respJson.value;

      while (nextUrlToFetchFrom) {
        const nextResp = await fetch(nextUrlToFetchFrom, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const respJson = await nextResp.json();
        allResults = allResults.concat(respJson.value);
        nextUrlToFetchFrom = respJson["@odata.nextLink"];
      }
    } catch (delegatedRelationsError) {
      console.error(delegatedRelationsError);
      allResults = null;
      setError("Error fetching domains");
    }

    return allResults;
  }

  const fetchPartnerCenterCustomerDetails = async (token) => {
    let allResults;
    let nextUrlToFetchFrom =
      "https://graph.microsoft.com/beta/tenantRelationships/delegatedAdminRelationships?$filter=status%20eq%20%27active%27&$select=displayName,status,customer,accessDetails,endDateTime,autoExtendDuration";

    // TODO - Refactor with recursion
    try {
      const resp = await fetch(nextUrlToFetchFrom, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const respJson = await resp.json();
      nextUrlToFetchFrom = respJson["@odata.nextLink"];
      allResults = respJson.value;

      while (nextUrlToFetchFrom) {
        const nextResp = await fetch(nextUrlToFetchFrom, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const respJson = await nextResp.json();
        allResults = allResults.concat(respJson.value);
        nextUrlToFetchFrom = respJson["@odata.nextLink"];
      }
    } catch (delegatedRelationsError) {
      console.error(delegatedRelationsError);
      allResults = null;
      setError("Error fetching delegated relationships");
    }

    return allResults;
  };

  const getPartnerCenterGraphToken = async (tenantId) => {
    const graphResponse = await refreshCspPcaCredential(
      `https://login.microsoftonline.com/${tenantId}`,
      delegatedAdminAuthScopes,
      true,
      "consent"
    );

    const token = graphResponse.accessToken;

    return token;
  };

  const getCustomers = async () => {
    setError(undefined);
    setLoading(true);

    try {
      const authResponse = await refreshCspPcaCredential(
        commonLoginAuthority,
        cspAuthScopes,
        false,
        "consent"
      );
      if (authResponse === undefined) {
        setError({
          title: "Error Authenticating",
          type: "error",
          message: (
            <p>
              {`We were unable to authenticate this user with the associated
                  Public Cloud Application. Ensure you're authenticating using the
                  correct Client Admin account`}
            </p>
          ),
        });
        setLoading(false);
        return;
      }

      // generate partner center token
      const tokenResponse = await fetchPartnerCenterToken(
        authResponse.accessToken
      );

      if (!tokenResponse.ok) {
        setError({
          title: "Warning",
          type: "warning",
          message: <p>Failed to retrieve partner center token</p>,
        });
        setLoading(false);
        return;
      }

      const token = (await tokenResponse.json()).access_token;

      // retrieve customers with partner center token
      const customerResponse = await fetchPartnerCenterCustomers(token);

      if (!customerResponse.ok) {
        setError({
          title: "Warning",
          type: "warning",
          message: <p>Failed to retrieve partner center customers</p>,
        });
        setLoading(false);
        return;
      }

      const customers = (await customerResponse.json()).items;

      if (customers.length === 0) {
        setError({
          title: "Warning",
          type: "warning",
          message: <p>No customers found for this account</p>,
        });
        setLoading(false);
        return;
      }

      const { tenantId } = authResponse;

      const partnerCenterGraphToken = await getPartnerCenterGraphToken(
        tenantId
      );

      const allDomains = await fetchPartnerCenterDomains(
        partnerCenterGraphToken
      )

      if (!allDomains) {
        setError({
          title: "Error",
          type: "error",
          message: (
            <p>An error occurred fetching domains</p>
          ),
        });
        setLoading(false);
        return;
      }

      const delegatedRelationships = await fetchPartnerCenterCustomerDetails(
        partnerCenterGraphToken
      );

      if (!delegatedRelationships) {
        setError({
          title: "Error",
          type: "error",
          message: (
            <p>An error occurred fetching delegated admin relationships</p>
          ),
        });
        setLoading(false);
        return;
      }

      // map each customer against the delegatedRelationships response to determine if there's an active relationship
      // note: status can include 'active' (active GDAP relationship), 'terminated' (no longer active), 'approvalPending' (pending approval)
      // if a tenant *does not* appear in this list, we mark them as `allowDelegatedAccess: false`
      // if a tenant *does* appear in this list, but does *not* have a status of 'active', we mark them as `allowDelegatedAccess: false`
      // otherwise, we mark the tenant as `allowDelegatedAccess: true`
      const customerDetails = customers.map((customer) => {
        const allFoundRelationships = delegatedRelationships.filter(
          (relationship) => relationship?.customer?.tenantId === customer.id
        );

        const isActive = allFoundRelationships.some(
          (relationship) => relationship?.status === "active"
        );

        const endDate = allFoundRelationships[0]?.endDateTime || null;
        const autoExtendDuration =
          allFoundRelationships[0]?.autoExtendDuration || null;

        return {
          ...customer,
          allowDelegatedAccess: isActive,
          unifiedRoles: allFoundRelationships[0]?.accessDetails?.unifiedRoles,
          endDateTime: endDate,
          autoExtendDuration: autoExtendDuration,
        };
      });


      try {
        setState((s) =>
          saveState(
            {
              ...s,
              cspEpaAdded: true,
              accessToken: token,
              domains: allDomains,
              customers: customerDetails.map((c) => ({
                ...c,
                partnerCenter: true,
              })),
            },
            isRefresh
          )
        );
      } catch (Error) {
        console.error(Error);
        setError({
          title: "Error",
          type: "error",
          message: <p>Failed to retrieve existing customers</p>,
        });
      }
    } catch (networkError) {
      console.error(networkError);
      setError({
        title: "Error",
        type: "error",
        message: <p>Failed to retrieve existing customers</p>,
      });
    }

    setLoading(false);
  };

  return {
    state,
    setState,
    error,
    loading,
    getCustomers,
    getCustomerSubscribedSkus,
  };
};
