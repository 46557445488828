import { library } from "@fortawesome/fontawesome-svg-core";
import { faMicrosoft, faFontAwesome } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faFontAwesome, faMicrosoft);

const MSLogin = ({ onClick }) => (
  <div>
    <div className="mx-auto max-w-3xl text-center">
      <h2 className="text-3xl font-bold text-inforcer-navy sm:text-4xl">
        GDAP Onboarding Assessment | inforcer
      </h2>

      <p className="mt-1 text-gray-500 pt-8 sm:text-xl">
        This aseessment will pull in all of your partner center customers and
        test each admin relationship (GDAP) for suitable roles to support the
        inforcer passwordless onboarding journey
      </p>
    </div>
    <div className="flex flex-col items-center">
      <button
        type="button"
        className="mx-auto mt-10 inline-flex items-center gap-2 rounded border border-inforcer-cyan bg-inforcer-cyan px-20 py-3 text-white hover:bg-transparent hover:text-inforcer-cyan focus:outline-none focus:ring active:text-inforcer-cyan"
        onClick={onClick}
      >
        <span className="text-lg font-medium">
          {" "}
          Sign in with Partner Center{" "}
        </span>

        <span className="text-xl font-large">
          <FontAwesomeIcon icon="fa-brands fa-microsoft" />
        </span>
      </button>
    </div>
  </div>
);

export default MSLogin;
