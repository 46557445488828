import React, { useMemo } from "react";
import { PieChart, Pie, Cell, Tooltip, Label, Legend } from "recharts";


const GdapPieChart = ({
    totalGdapRoles,
    totalAppliedGdapRoles,
    appliedInforcerGdapRoles,
    missingInforcerGdapRoles,
}) => {
  const COLORS = ["#a1a1aa", "#171B3A", "#44aa44", "#aa4444"];

  const data = useMemo(() => {
    let totalGdapRolesDiff = totalGdapRoles - totalAppliedGdapRoles
    let otherGdapRoles = totalAppliedGdapRoles - appliedInforcerGdapRoles

    return [
      { name: "Available GDAP Roles", value: totalGdapRolesDiff },
      {
        name: "Other GDAP Roles",
        value: otherGdapRoles,
      },
      { name: "Applied inforcer Roles", value: appliedInforcerGdapRoles },
      { name: "Missing inforcer Roles", value: missingInforcerGdapRoles },
    ];
  }, [totalGdapRoles, totalAppliedGdapRoles, missingInforcerGdapRoles, appliedInforcerGdapRoles]);

  const renderCustomizedLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox;
    return (
      <g>
        <text
          x={cx}
          y={cy + 8}
          textAnchor="middle"
          fontSize="54px"
          fontWeight="bold"
          fill="#171B3A"
        >
          {missingInforcerGdapRoles}
        </text>
        <text
          x={cx}
          y={cy + 30}
          textAnchor="middle"
          fontSize="16px"
          fill="#6b7280"
        >
          Missing Roles
        </text>
      </g>
    );
  };

  const renderLegendText = (value, entry) => {
    const { value: count } = entry.payload;
    return `${value}: ${count}`;
  };

  return (
    <div className="flex items-center justify-center">
      <PieChart width={320} height={330}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={90}
          outerRadius={112}
          paddingAngle={2}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <Label content={renderCustomizedLabel} position="center" />
        </Pie>
        <Tooltip />
        <Legend
          formatter={renderLegendText}
          align="left"
          wrapperStyle={{ bottom: "-5px" }}
        />
      </PieChart>
    </div>
  );
};

export default GdapPieChart;
